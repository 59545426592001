<template>
  <div v-if="template">
    <div class="row">
      <div class="col">
        <ol class="breadcrumb breadcrumb-arrows mb-3" aria-label="breadcrumbs">
          <li class="breadcrumb-item">
            <router-link :to="`/reputation/templates/`">
              Templates
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            <a href="#"> {{ template.name }} </a>
          </li>
        </ol>
      </div>
      <div class="col-auto"></div>
    </div>

    <div class="card">
      <div class="card-header d-flex">
        <h3 class="card-title">{{ template.name }}</h3>
        <button @click="preview(false)" class="btn btn-sm btn-outline ms-auto">
          Show Preview
        </button>
      </div>

      <div>
        <div
          class="save-bar bg-white d-flex border align-items-center p-2 shadow-sm"
        >
          <span class="text-muted" v-if="!dirty">Template is editable</span>
          <span class="ms-auto me-2 text-muted" v-if="!dirty">
            No changes yet
          </span>
          <button
            class="btn btn-sm btn-outline me-auto"
            v-if="dirty"
            @click="preview(true)"
          >
            Preview Changes
          </button>
          <button
            class="btn btn-sm btn-outline-danger me-2"
            v-if="dirty"
            @click.prevent="revert"
          >
            Revert Changes
          </button>
          <button
            class="btn btn-sm btn-primary"
            v-if="dirty"
            @click="saveChanges"
          >
            Save
          </button>
        </div>
        <prism-editor
          class="my-editor"
          v-model="code"
          :highlight="highlighter"
          line-numbers
        ></prism-editor>
      </div>
    </div>

    <preview-template-modal
      v-if="template"
      :privateKey="template.private_key"
      :previewUnsaved="previewUnsaved"
      :code="code"
      :additionalId="``"
    ></preview-template-modal>
  </div>
</template>

<style>
/* required class */

.save-bar {
  position: sticky;
  top: 0;
  z-index: 15;
}

.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: white;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>

<script>
import ReputationManagementService from "../../services/ReputationManagementService";
import PreviewTemplateModal from "./Template/PreviewTemplateModal.vue";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css"; // import syntax highlighting styles

export default {
  components: { PreviewTemplateModal, PrismEditor },
  name: "reputation.template-detail",
  data: () => {
    return {
      template: null,
      code: null,
      previewUnsaved: false,
    };
  },
  computed: {
    dirty() {
      return this.code !== this.template.template;
    },
  },
  methods: {
    async saveChanges() {
      let template = this.template;
      template.template = this.code;
      this.template = await ReputationManagementService.updateTemplate(
        this.$auth.activeCompany,
        template
      );
      this.code = this.template.template;
    },
    preview(saved) {
      this.previewUnsaved = saved;
      this.$nextTick(() => {
        this.$bvModal.show("preview-template-modal");
      });
    },
    revert() {
      let confirm = window.confirm(
        "This will undo all your changes. Are you sure you want to revert?"
      );
      if (confirm) {
        this.code = this.template.template;
      }
    },
    highlighter(code) {
      return highlight(code, languages.markup); // languages.<insert language> to return html with markup
    },
    async reloadTemplate() {
      this.template = await ReputationManagementService.getTemplate(
        this.$auth.activeCompany,
        this.$route.params.privateKey
      );
      this.code = this.template.template;
    },
  },
  async mounted() {
    await this.reloadTemplate();
  },
};
</script>
